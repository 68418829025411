export default {
  selectors: {
    searchMenu: '.cmp-search__menu',
    languageNavigationMenu: '.cmp-language-navigation',
    menuButton: '.batcom-menuButton__base',
    navWrapper: '.batcom-navigation',
    navBase: '.cmp-navigation',
    topLevelNavLink: '.cmp-navigation__item--level-0 > .cmp-navigation__item-link',
    navGroup: '.cmp-navigation__group',
    navItem: '.cmp-navigation__item',
    navItemLink: '.cmp-navigation__item-link',
    activeNavItem: '.cmp-navigation__item--active',
    activeNavItemGroup: '.cmp-navigation__item--active > .cmp-navigation__group',
    navTopGroup: '.cmp-navigation > .cmp-navigation__group',
    level1Group: '.cmp-navigation__item--level-1 > .cmp-navigation__group',
    level1Item: '.cmp-navigation__item--level-1',
    hiddenTranslationText: '#hiddenTranslationText',
    itemMegaNavMobile: '.cmp-navigation__item--mega-nav',
    vuseHeaderImgBanner: '.vuse-header-img-banner',
    vuseCanadaHeader: '.batcom-header-var-vuseCanada',
    batcomHeaderLinks: '.batcom-header__links',
    navHeader: '.batcom-navigation--header',
    menuButtonLabel: '.batcom-menuButton__label',
  },
  classes: {
    batcomHide: 'batcom-hide',
    open: 'open',
    visible: 'visible',
    active: 'active',
    navBase: 'cmp-navigation',
    navLevelPrefix: 'cmp-navigation__item--level-',
    level0Item: 'cmp-navigation__item--level-0',
    level1Item: 'cmp-navigation__item--level-1',
    level2Item: 'cmp-navigation__item--level-2',
    navItemLink: 'cmp-navigation__item-link',
    arrow: 'batcom-navigation__arrow',
    arrowRight: 'batcom-navigation__arrow--right',
    arrowLeft: 'batcom-navigation__arrow--left',
    noScroll: 'noScroll',
    burgerMenuActive: 'batcom-burgerMenu--active',
    aemGrid: 'aem-GridColumn',
    navHeader: 'batcom-navigation--header',
    level0Button: 'cmp-navigation__item-button',
    level0ButtonActive: 'cmp-navigation__item-icon-button--active',
    vuseCanadaHeader: 'batcom-header-var-vuseCanada',
  },
  customProps: {
    navCurrentWidth: '--batcom-navigation-current-width',
    navCurrentLevel: '--batcom-navigation-current-level',
    navCurrentMinHeight: '--batcom-navigation-current-min-height',
    contentLeftRightMargin: '--batcom-layout-content-left-right-margin',
  },
};
