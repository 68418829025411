import { register } from '@netcentric/component-loader';
import config from './batcom-navigation.config';
import { isOnMobileOrTablet, isOnDesktop, removeBodyScroll, addBodyScroll, scrollToTop } from '../../../commons/utils';
import * as Events from '../../../commons/constants/events';

class Navigation {
  constructor(el) {
    this.el = el;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.customProps = config.customProps;
    this.burgerMenuIsActive = false;
    this.activeLevelIsSet = false;
    this.navLinkClicked = false;
    this.init();
  }

  init() {
    this.setRefs();
    this.setEnhancements();
    this.setPubSubListeners();
    this.setEventListeners();
    this.showNav();
    this.handleDesktopNav();
    this.handleMobileMegaNav();
    Navigation.setMainContentID();
  }

  setRefs() {
    this.body = document.body;
    this.menuButton = this.el.parentElement.querySelector(this.selectors.menuButton);
    this.allNavGroups = this.el.querySelectorAll(this.selectors.navGroup);
    this.allNavItems = this.el.querySelectorAll(this.selectors.navItem);
    this.allNavLinks = this.el.querySelectorAll(this.selectors.navItemLink);
    this.activeNavItem = [...this.el.querySelectorAll(this.selectors.activeNavItem)].pop();
    this.navTopGroup = this.el.querySelector(this.selectors.navTopGroup);
    this.topLevelNavLink = this.el.querySelectorAll(this.selectors.topLevelNavLink);
    this.navWrapper = this.el.parentElement;
    this.hiddenTranslationText = document.querySelector(this.selectors.hiddenTranslationText);
    this.navGroup = this.body.querySelectorAll(this.selectors.navGroup);
    this.vuseHeaderImgBanner = this.body.querySelector(this.selectors.vuseHeaderImgBanner);
    this.vuseCanadaHeader = this.body.querySelector(this.selectors.vuseCanadaHeader);
    this.batcomHeaderLinks = this.body.querySelector(this.selectors.batcomHeaderLinks);
    this.navHeader = this.body.querySelector(this.selectors.navHeader);
  }

  handleMobileMegaNav() {
    if (isOnMobileOrTablet()) {
      const liMegaNavItems = this.el.querySelectorAll(this.selectors.itemMegaNavMobile);
    
      if (liMegaNavItems.length > 0) {
        liMegaNavItems.forEach(liItem => {
          const liItemSiblings = liItem.parentNode.childNodes;
          const navItemLink = liItem.querySelector(this.selectors.navItemLink);
          const childBtn = document.createElement('button');
          childBtn.classList.add(this.classes.level0Button );
          childBtn.setAttribute('aria-label', this.hiddenTranslationText.dataset.label);
          childBtn.setAttribute('tabindex', '-1');
          navItemLink.after(childBtn);
          childBtn.addEventListener('click', () => this.handleNavLinkClick(liItem, liItemSiblings));
        });
      }
    }
  }

  handleDesktopNav() {
    if (isOnDesktop() && !this.burgerMenuIsActive) {
      const megaNavElems = this.el.querySelectorAll('[data-desktop-meganav]');
      if (megaNavElems.length > 0) {
        this.handleDesktopMegaNav(megaNavElems);
      } else {
        this.addSubLevelIndicator();
        this.tabbedNavigationMenuImprovements();
      }
    }
  }

  handleDesktopMegaNav(deskopMegaNavItems) {
    deskopMegaNavItems.forEach(megaNavItem => {
      const contentWrapper = megaNavItem.querySelector('.cmp-navigation__mega-nav-desktop-flyout');
      const remoteUrl = megaNavItem.getAttribute('data-desktop-meganav');
      const remoteContent = fetch(remoteUrl);
      remoteContent.then(response => response.text()).then(data => {
        contentWrapper.insertAdjacentHTML('beforeend', data);
        this.reinitScripts(contentWrapper);
      });
    });
  }

  reinitScripts(contentWrapper) {
    const scripts = contentWrapper.querySelectorAll('script');
    scripts.forEach(script => {
      const theNewScript = document.createElement('script');
      [...script.attributes]
      .forEach((attr) => theNewScript.setAttribute(attr.name, attr.value));
    theNewScript.appendChild(document.createTextNode(script.innerHTML));
    script.parentNode.replaceChild(theNewScript, script);
    });
  }

  setEnhancements() {
    if (isOnDesktop()) {
      this.navHeader.classList.remove(this.classes.batcomHide);
    }

    this.allNavItems.forEach(item => {
      const childrenGroup = this.getChildrenGroup(item);

      if (childrenGroup) {
        const link = item.querySelector(this.selectors.navItemLink);
        const newLink = link.cloneNode(true);
        newLink.classList.add('clone');

        const childBtn = document.createElement('button');
        childBtn.classList.add(this.classes.arrow, this.classes.arrowRight);
        childBtn.setAttribute('aria-label', this.hiddenTranslationText.dataset.label);
        childBtn.setAttribute('tabindex', '-1');
        link.appendChild(childBtn);

        childrenGroup.insertBefore(newLink, childrenGroup.firstChild);

        const backBtn = document.createElement('button');
        backBtn.classList.add(this.classes.arrow, this.classes.arrowLeft);
        backBtn.setAttribute('aria-label', 'Previous level navigation icon');
        backBtn.addEventListener('click', e => this.setActiveLevel(item, e, true, 0));
        childrenGroup.insertBefore(backBtn, childrenGroup.firstChild);
      }
    });

    const navLabel = this.el.getAttribute('aria-label');
    if (navLabel && navLabel !== '') {
      this.navWrapper.dataset.label = navLabel;
    }

    
    if (isOnMobileOrTablet()) {
      // Set the top position of the menu to be below the header image banner
      if (this.vuseCanadaHeader && this.vuseHeaderImgBanner) {
        const imageBannerHeight = this.vuseHeaderImgBanner.parentElement.parentElement.parentElement.parentElement.offsetHeight;
        const vuseCanadaHeaderHeight = this.vuseCanadaHeader.offsetHeight;
        if (imageBannerHeight && vuseCanadaHeaderHeight) {
          this.el.style.setProperty('top', `${imageBannerHeight + vuseCanadaHeaderHeight }px`);
        }
      }

      // Move Menu icon on the left side of the batcom-header__links
      if (this.vuseCanadaHeader && this.navHeader && this.batcomHeaderLinks) {
        const menuButtonLabel = this.navHeader.querySelector(this.selectors.menuButtonLabel);
        this.navHeader.after(this.batcomHeaderLinks);
        this.navHeader.classList.remove(this.classes.batcomHide);
        menuButtonLabel.classList.remove(this.classes.batcomHide);
      }
    }
  }

  setPubSubListeners() {
    window.PubSub.subscribe(Events.OPEN_NAVIGATION, () => {
      this.openMenu();
    });

    window.PubSub.subscribe(Events.CLOSE_NAVIGATION, () => {
      this.closeMenu();
    });
  }

  setEventListeners() {
    const isStandalone = !this.navWrapper.classList.contains(this.classes.navHeader);

    window.addEventListener('click', () => this.onWindowClick());
    window.addEventListener('resize', () => this.onWindowResize());
    window.addEventListener('keyup', e => this.closeNavGroupByKeyboard(e));
    this.menuButton.addEventListener('click', event => this.onMenuClick(event));

    if (!this.vuseCanadaHeader) {
      this.allNavLinks.forEach(navLink => {
        navLink.addEventListener('click', event => this.onNavLinkClick(event));
      });
    }

    this.navTopGroup.addEventListener('mouseleave', event => this.onNavTopGroupMouseLeave(event));

    if (isStandalone) {
      this.el.addEventListener('mouseenter', () => this.setZIndex());
      this.el.addEventListener('mouseleave', () => this.resetZIndex());
    }
  }

  onWindowClick() {
    if (isOnDesktop()) {
      const navIsHidden = !this.el.classList.contains(this.classes.visible);

      if (this.burgerMenuIsActive && navIsHidden) {
        this.resetFlyout();
      }
    }
  }

  onWindowResize() {
    const navIsVisible = this.el.classList.contains(this.classes.visible);

    if (isOnDesktop() && navIsVisible) {
      this.closeMenu();

      if (this.burgerMenuIsActive) {
        this.resetFlyout();
      }
    }
  }

  onNavTopGroupMouseLeave() {
    if (isOnDesktop() && this.navLinkClicked) {
      const navItems = this.el.querySelectorAll(this.selectors.navItem);
      this.removeActiveClass(navItems);
      this.resetMenu();
    }
  }

  openMenu() {
    const isInResponsiveGrid = this.navWrapper.classList.contains(this.classes.aemGrid);
    const isStandalone = !this.navWrapper.classList.contains(this.classes.navHeader);
    const docStyles = getComputedStyle(document.documentElement);
    const contentLeftRightMargin = Number(docStyles.getPropertyValue(this.customProps.contentLeftRightMargin).split('px')[0]);
    const contentLeftMargin = contentLeftRightMargin / 2;
    this.setZIndex();

    if (isOnMobileOrTablet()) {
      if (isStandalone) {
        this.setMenuWidth(contentLeftRightMargin);
        this.setMenuHeight();

        if (isInResponsiveGrid) {
          this.setMenuLeft(contentLeftRightMargin, true);
        } else {
          this.setMenuLeft(contentLeftMargin);
        }
      }
    }

    if (isOnDesktop() && isStandalone) {
      this.setMenuWidth(false);
      this.setMenuLeft(contentLeftMargin, true, true);
    }

    this.menuButton.classList.add(this.classes.open);
    this.el.classList.add(this.classes.visible);
  }

  setZIndex() {
    const zIndex = Number(window.getComputedStyle(this.navWrapper).zIndex);
    this.navWrapper.style.zIndex = zIndex + 1;
  }

  setMenuWidth(contentLeftRightMargin) {
    const containerWidth = this.navWrapper.parentElement.offsetWidth;
    const menuWidth = contentLeftRightMargin ? containerWidth + contentLeftRightMargin : containerWidth;
    this.el.style.width = `${menuWidth}px`;
  }

  setMenuHeight() {
    const docHeight = document.height !== undefined ? document.height : document.body.offsetHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const navTop = this.el.getBoundingClientRect().top;
    const menuTop = scrollTop + navTop;
    const menuHeight = docHeight - menuTop;
    this.el.style.height = `${menuHeight}px`;
  }

  setMenuLeft(contentLeftMargin, isInResponsiveGrid, onDesktop) {
    const containerLeft = this.navWrapper.parentElement.getBoundingClientRect().left;
    const navLeft = this.navWrapper.getBoundingClientRect().left;
    let menuLeft = navLeft - containerLeft;

    if (!onDesktop) {
      menuLeft -= contentLeftMargin;
    }

    if (isInResponsiveGrid) {
      if (onDesktop) {
        this.el.style.left = `-${menuLeft}px`;
      } else {
        this.el.style.left = `-${navLeft}px`;
      }
    } else {
      this.el.style.left = `${menuLeft}px`;
    }
  }

  closeMenu() {
    const isInResponsiveGrid = this.navWrapper.classList.contains(this.classes.aemGrid);
    const isStandalone = !this.navWrapper.classList.contains(this.classes.navHeader);
    this.menuButton.classList.remove(this.classes.open);
    this.el.classList.remove(this.classes.visible);
    this.resetZIndex();

    if (isOnMobileOrTablet()) {
      if (isStandalone) {
        this.resetMenuWidth();
        this.resetMenuHeight();
        this.resetMenuLeft();
      }
    }

    if (isOnDesktop() && isInResponsiveGrid) {
      this.resetMenuWidth();
      this.resetMenuLeft();
    }

    this.resetMenu();
  }

  resetZIndex() {
    const zIndex = window.getComputedStyle(this.navWrapper).zIndex;
    this.navWrapper.style.zIndex = zIndex - 1;
  }

  resetMenuHeight() {
    this.el.style.height = 'initial';
  }

  resetMenuWidth() {
    this.el.style.width = 'initial';
  }

  resetMenuLeft() {
    this.el.style.left = 0;
  }

  onMenuClick(event) {
    event.preventDefault();
    const menuIsOpen = this.menuButton.classList.contains(this.classes.open);

    if (menuIsOpen) {
      this.closeMenu();

      if (isOnMobileOrTablet()) {
        this.handleBodyScroll(menuIsOpen);
      }

      if (isOnDesktop()) {
        this.resetFlyout();
      }
    } else {
      this.openMenu();

      if (isOnMobileOrTablet()) {
        this.handleBodyScroll(menuIsOpen);
      }
    }

    this.resetMenu();
  }

  handleBodyScroll(menuIsOpen) {
    const isStandalone = !this.navWrapper.classList.contains(this.classes.navHeader);

    if (menuIsOpen && !isStandalone) {
      removeBodyScroll(this.classes.noScroll);
    } else if (!isStandalone) {
      addBodyScroll(this.classes.noScroll);
    }
  }

  resetMenu() {
    this.allNavGroups.forEach(group => {
      if (!group.parentElement.classList.contains(this.classes.navBase)) {
        group.classList.remove(this.classes.visible);
        group.style.removeProperty(this.customProps.navCurrentWidth);
        group.style.removeProperty(this.customProps.navCurrentMinHeight);
      }
    });

    this.el.style.setProperty(this.customProps.navCurrentLevel, 0);
  }

  onNavLinkClick(event) {
    const menuButtonIsActive = this.el.classList.contains(this.classes.burgerMenuActive);
    const item = event.target.closest(this.selectors.navItem);
    const itemSiblings = item.parentNode.childNodes;
    this.setActiveLevel(item, event, true);

    if (isOnMobileOrTablet()) {
      this.handleNavLinkClick(item, itemSiblings);
    } else if (isOnDesktop() && !menuButtonIsActive) {
      this.handleNavLinkClick(item, itemSiblings);
      this.navLinkClicked = true;
    } else if (isOnDesktop() && menuButtonIsActive) {
      this.handleNavLinkClickFlyout(item, itemSiblings);
    }
  }

  handleNavLinkClick(item, itemSiblings) {
    this.removeActiveClass(itemSiblings, item);
    item.classList.toggle(this.classes.active);
  }

  handleNavLinkClickFlyout(item, itemSiblings) {
    const itemIsLevel0 = item.classList.contains(this.classes.level0Item);
    const itemIsActive = item.classList.contains(this.classes.active);
    const itemHasChildren = item.querySelector(this.selectors.navGroup);
    this.removeActiveClass(itemSiblings, item);

    itemSiblings.forEach(sibling => {
      if (Navigation.isDOMElement(sibling)) {
        this.resetLevel1ItemsStyles(sibling);
      }
    });

    if (itemIsLevel0 && itemIsActive) {
      this.resetLevel1ItemsStyles(item);
    }

    item.classList.toggle(this.classes.active);

    if (itemHasChildren) {
      this.resizeFlyoutMenu(item);
    }
  }

  removeActiveClass(els, item) {
    els.forEach(el => {
      if (item && item === el) {
        return;
      }

      const classList = el.classList;

      if (classList) {
        classList.remove(this.classes.active);
      }
    });
  }

  setActiveLevel(item, event, reset, offset = 1) {
    if (reset) {
      this.resetMenu();
    }

    let currentLevel = this.getItemNavLevel(item);
    const childrenGroup = this.getChildrenGroup(item);
    const ancestors = this.getAncestors(item);
    const menuButtonIsActive = this.el.classList.contains(this.classes.burgerMenuActive);

    if (this.activeNavItem) {
      const activeNavItemGroup = this.activeNavItem.querySelector(this.selectors.activeNavItemGroup);

      if (activeNavItemGroup) {
        activeNavItemGroup.classList.remove(this.classes.visible);
      }
    }

    this.addVisibleClassAncestors(ancestors, menuButtonIsActive);

    if (childrenGroup) {
      const isLevel2Item = childrenGroup.parentNode.classList.contains(this.classes.level2Item);
      currentLevel += offset;

      if (isOnMobileOrTablet() || !menuButtonIsActive) {
        childrenGroup.classList.add(this.classes.visible);
      }

      scrollToTop(this.el);

      const preventClick = isOnMobileOrTablet() || currentLevel === 2 || (menuButtonIsActive && !isLevel2Item);

      if (event && preventClick) {
        event.preventDefault();
      }
    }

    this.el.style.setProperty(this.customProps.navCurrentLevel, currentLevel);

    if (isOnDesktop() && currentLevel === 2 && childrenGroup && !menuButtonIsActive) {
      this.resizeFloatingMenu(item, childrenGroup);
    }
  }

  addVisibleClassAncestors(ancestors, menuButtonIsActive) {
    ancestors.forEach(ancestor => {
      const addVisibleClass = isOnDesktop() && menuButtonIsActive && ancestor.parentNode.classList.contains(this.classes.navBase);

      if (addVisibleClass) {
        ancestor.classList.add(this.classes.visible);
      } else if (isOnMobileOrTablet()) {
        ancestor.classList.add(this.classes.visible);
      }
    });
  }

  resizeFloatingMenu(item, childrenGroup) {
    const itemParentStyles = window.getComputedStyle(item.parentElement);
    const parentPadding = Number(itemParentStyles.paddingLeft.split('px')[0]) + Number(itemParentStyles.paddingRight.split('px')[0]);
    const childrenGroupStyles = window.getComputedStyle(childrenGroup);
    const childrenGroupBorders =
      Number(childrenGroupStyles.borderLeftWidth.split('px')[0]) + Number(childrenGroupStyles.borderRightWidth.split('px')[0]);
    const extraWidth = childrenGroup.getBoundingClientRect().width - childrenGroupBorders;
    const currentWidth = item.getBoundingClientRect().width + parentPadding;
    const newWidth = Math.max(currentWidth, extraWidth);
    const level1Group = item.querySelector(this.selectors.level1Group);
    const level1GroupStyles = window.getComputedStyle(level1Group);
    const level1GroupMargin = Number(level1GroupStyles.marginTop.split('px')[0]) + Number(level1GroupStyles.marginBottom.split('px')[0]);
    const level1GroupHeight = level1Group.scrollHeight;

    this.allNavItems.forEach(other => {
      if (!other.classList.contains(this.classes.level0Item)) {
        other.style.width = `${currentWidth - parentPadding}px`;
      }
    });
    item.parentElement.style.setProperty(this.customProps.navCurrentWidth, `${newWidth * 2}px`);
    item.parentElement.style.setProperty(this.customProps.navCurrentMinHeight, `${level1GroupHeight + level1GroupMargin}px`);
  }

  getChildrenGroup(item) {
    return item.querySelector(this.selectors.navGroup);
  }

  getAncestors(item, ancestors = []) {
    const parentGroup = item.closest(this.selectors.navGroup);

    if (parentGroup) {
      ancestors.push(parentGroup);
      return this.getAncestors(parentGroup.parentElement, ancestors);
    }
    return ancestors;
  }

  getItemNavLevel(item) {
    const currentLevelClass = [...item.classList].find(cls => cls.startsWith(this.classes.navLevelPrefix));
    const [_, currentLevel] = currentLevelClass.split(this.classes.navLevelPrefix); // eslint-disable-line
    return Number(currentLevel);
  }

  showNav() {
    const menuButtonIsActive = this.el.classList.contains(this.classes.burgerMenuActive);

    if (menuButtonIsActive) {
      this.menuButton.classList.add(this.classes.visible);
    }

    this.navTopGroup.classList.add(this.classes.visible);
  }

  resetFlyout() {
    const navItems = this.el.querySelectorAll(this.selectors.navItem);
    const navGroups = this.el.querySelectorAll(this.selectors.navGroup);

    this.removeActiveClass(navItems);

    navGroups.forEach(group => {
      group.style.height = 'auto';
    });
  }

  resetLevel1ItemsStyles(item) {
    const level1Items = item.querySelectorAll(this.selectors.level1Item);
    this.removeActiveClass(level1Items);

    level1Items.forEach(level1Item => {
      const navGroup = level1Item.querySelector(this.selectors.navGroup);

      if (navGroup) {
        navGroup.style.height = 'auto';
      }
    });
  }

  resizeFlyoutMenu(item) {
    let parentHeight;
    const navGroup = item.querySelector(this.selectors.navGroup);
    const level0Item = item.classList.contains(this.classes.level0Item);
    const level1Item = item.classList.contains(this.classes.level1Item);
    const navItemIsActive = item.classList.contains(this.classes.active);
    const navTopGroupStyles = window.getComputedStyle(this.navTopGroup);
    const navTopGroupHeight = Number(navTopGroupStyles.height.split('px')[0]);

    if (level0Item || level1Item) {
      this.navTopGroup.style.height = 'auto';
      if (navGroup) {
        navGroup.style.height = 'auto';
      }
    }

    if (level1Item) {
      const parent = item.parentNode;
      parentHeight = parent.offsetHeight;
    }

    const navGroupStyles = window.getComputedStyle(navGroup);
    const navGroupHeight = Number(navGroupStyles.height.split('px')[0]);

    if (level1Item && navItemIsActive) {
      navGroup.style.height = '100%';
    } else if (level1Item && !navItemIsActive) {
      this.navTopGroup.style.height = 'auto';
      navGroup.style.height = 'auto';
    }

    if (navGroupHeight > navTopGroupHeight || level0Item) {
      this.navTopGroup.style.height = `${navGroupHeight}px`;
    } else if (isNaN(navGroupHeight)) {
      const level1Group = navGroup.parentNode.parentNode;
      const level1GroupStyles = window.getComputedStyle(level1Group);
      const level1GroupHeight = Number(level1GroupStyles.height.split('px')[0]);
      this.navTopGroup.style.height = `${level1GroupHeight}px`;
    } else if (level1Item && navGroupHeight > parentHeight) {
      this.navTopGroup.style.height = `${navGroupHeight}px`;
    } else if (level1Item) {
      this.navTopGroup.style.height = `${parentHeight}px`;
    }
  }

  static isDOMElement(el) {
    return el instanceof Element || el instanceof Document;
  }

  addSubLevelIndicator() {
    // If sub menu is available bellow the top level navigation items, add drop down button to navigation
    this.topLevelNavLink.forEach(link => {
      if (link.nextElementSibling) {
        const btn = `<button class="submenu-trigger" aria-label='show submenu for ${link.text} link'></button>`;
        link.insertAdjacentHTML('afterend', btn);
        link.parentElement.classList.add('has-submenu');
      }
    });
  }

  tabbedNavigationMenuImprovements() {
    // Make sub menu items available to tabbed navigation (accessible by keyboard tabbed navigation)
    const menuTriggerButtons = this.el.querySelectorAll('.submenu-trigger');

    menuTriggerButtons.forEach(menuTriggerButton => {
      menuTriggerButton.addEventListener('keyup', function (event) {
        if (event.code === 'Space' || event.code === 'Enter') {
          const navGroup = menuTriggerButton.nextElementSibling;

          if (this.parentNode.classList.contains('has-submenu')) {
            if (!this.parentNode.classList.contains('open')) {
              navGroup.style.display = 'block';
              navGroup.style.overflow = 'hidden';
              this.parentNode.classList.toggle('open');
              this.parentNode.querySelector('a').setAttribute('aria-expanded', 'true');
              menuTriggerButton.setAttribute('aria-expanded', 'true');
            } else if (this.parentNode.classList.contains('open')) {
              this.parentNode.classList.toggle('open');
              this.parentNode.querySelector('a').setAttribute('aria-expanded', 'false');
              menuTriggerButton.setAttribute('aria-expanded', 'false');
              navGroup.style.display = 'none';
              navGroup.style.removeProperty('display');
            }
          }
          event.preventDefault();
        }
      });
    });
  }

  // Add ID attribute to page main content for skip links functionality
  static setMainContentID() {
    const container = document.querySelector('.root .cmp-container');
    const content = container.querySelector(':scope > div:not(.experiencefragment)');
    content.setAttribute('id', 'mainContent');
  }

  closeNavGroupByKeyboard(e) {
    this.navGroup.forEach(group => {
      if (group.style.display === 'block' && e.keyCode === 27) {
        const parentElement = group.parentElement;
        if (parentElement.classList.contains('open')) {
          parentElement.classList.remove('open');
          group.style.display = '';
        }
      }
    });
  }
}

register({ Navigation });
